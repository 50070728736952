import $ from 'jquery';
import {CheckInputFloat,CheckInputInt} from '@/utils/index';
import {usePermission} from "@/store/permission";

export  const hasPerm = {
    install: (app)=>{
		const permission = usePermission();

		 app.directive('hasPerm', {
			  mounted(el, binding){
				  let flag=false;
					  var permissions = permission.permission;
					  if(permissions){
						  if( binding.value){
							  const value = binding.value
							  flag = permissions.has(value) ;
						  }else{flag=false;}
					  } else{
						  flag=true;
					  }
				  
				  if (!flag) {
				      if (!el.parentNode) {
				          el.style.display = 'none'
				      } else {
				          el.parentNode.removeChild(el)
				      }
				  }
			  }
		 });
	}
}　

export  const dataType = {
    install:(app)=>{
		 app.directive('dataType', {
		 		 			  mounted(el, binding){
								  var $input=$(el).find("input");
								  if($input!=undefined&&$input.length>0){
									  var input=($(el).find("input"))[0];
									    if(binding.arg){
									  	  const value = binding.arg;
									  	  if(value=="float"){
											input.addEventListener('input',function(){
												this.value=CheckInputFloat(this.value);
												},true);
									  		 // input.oninput=function(){
									  		 // 	   this.value=CheckInputFloat(this.value)
									  		 // }
									  	  }
									  	  if(value=="int"){
											  input.addEventListener('input',function(){
											  	 this.value=CheckInputInt(this.value);
											  	},true);
									  		  // input.oninput=function(){
									  		  // 	   this.value=CheckInputInt(this.value)
									  		  // }
									  	  }
									    } 
								  }
								   
		 		 				 
		 		 			  }
		 		 });
	}
}
 