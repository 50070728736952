<script setup>
import { isArray } from '@/utils/validate';


var data1 = [
        {
            "id": "48",
            "label": "跟卖",
            "children": [
                {
                    "id": "66600",
                    "label": "AMZ跟卖",
                    "children": [
                        {
                            "id": "66601",
                            "label": "跟卖管理"
                        }
                    ]
                }
            ]
        },
        {
            "id": "81",
            "label": "计算器",
            "children": [
                {
                    "id": "1",
                    "label": "计算器运用",
                    "children": [
                        {
                            "id": "35",
                            "label": "利润计算"
                        },
                        {
                            "id": "102",
                            "label": "计算方案",
                            "children": [
                                {
                                    "id": "37",
                                    "label": "利润计算方案设置"
                                },
                                {
                                    "id": "65",
                                    "label": "订单统计规则"
                                },
                                {
                                    "id": "69",
                                    "label": "标签配置"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "id": "16",
            "label": "产品",
            "children": [
                {
                    "id": "126",
                    "label": "产品组",
                    "children": [
                        {
                            "id": "98",
                            "label": "产品管理"
                        },
                        {
                            "id": "17",
                            "label": "标签管理"
                        }
                    ]
                },
                {
                    "id": "127",
                    "label": "产品基础数据",
                    "children": [
                        {
                            "id": "18",
                            "label": "品牌管理"
                        },
                        {
                            "id": "19",
                            "label": "品类管理"
                        }
                    ]
                },
                {
                    "id": "128",
                    "label": "辅料包材",
                    "children": [
                        {
                            "id": "125",
                            "label": "辅料管理"
                        },
                        {
                            "id": "1251",
                            "label": "箱子管理"
                        }
                    ]
                },
                {
                    "id": "129",
                    "label": "产品组四",
                    "children": [
                        {
                            "id": "110",
                            "label": "SKU配对"
                        }
                    ]
                }
            ]
        },
        {
            "id": "121",
            "label": "销售",
            "children": [
                {
                    "id": "2",
                    "label": "商品管理",
                    "children": [
                        {
                            "id": "43",
                            "label": "商品分析"
                        },
                        {
                            "id": "163",
                            "label": "调价队列"
                        },
                        {
                            "id": "24",
                            "label": "趋势分析"
                        },
                        {
                            "id": "39",
                            "label": "销售报表"
                        },
                        {
                            "id": "114",
                            "label": "销量详情"
                        },
                        {
                            "id": "106",
                            "label": "销售计划"
                        },
                        {
                            "id": "1061",
                            "label": "商品搜索"
                        }
                    ]
                },
                {
                    "id": "8",
                    "label": "订单管理",
                    "children": [
                        {
                            "id": "92",
                            "label": "订单列表"
                        },
                        {
                            "id": "131",
                            "label": "退货订单"
                        },
                        {
                            "id": "66",
                            "label": "今日订单"
                        },
                        {
                            "id": "74",
                            "label": "FBA滞销"
                        }
                    ]
                },
                {
                    "id": "9",
                    "label": "商品周边",
                    "children": [
                        {
                            "id": "97",
                            "label": "FBA费用"
                        },
                        {
                            "id": "101",
                            "label": "流量报表"
                        }
                    ]
                }
            ]
        },
        {
            "id": "199",
            "label": "采购",
            "children": [
                {
                    "id": "306",
                    "label": "采购处理",
                    "children": [
                        {
                            "id": "31",
                            "label": "采购规划"
                        },
                        {
                            "id": "34",
                            "label": "采购单"
                        },
                        {
                            "id": "70",
                            "label": "加工单"
                        },
                        {
                            "id": "236",
                            "label": "请款单"
                        }
                    ]
                },
                {
                    "id": "307",
                    "label": "采购基础数据",
                    "children": [
                        {
                            "id": "44",
                            "label": "供应商"
                        }
                    ]
                },
                {
                    "id": "299",
                    "label": "采购报表",
                    "children": [
                        {
                            "id": "202",
                            "label": "采购入库明细"
                        },
                        {
                            "id": "52",
                            "label": "采购统计"
                        },
                        {
                            "id": "32",
                            "label": "采购付款明细"
                        },
                        {
                            "id": "78",
                            "label": "采购详情"
                        }
                    ]
                }
            ]
        },
        {
            "id": "63",
            "label": "仓库",
            "children": [
                {
                    "id": "30",
                    "label": "基础信息",
                    "children": [
                        {
                            "id": "89",
                            "label": "仓库列表"
                        },
                        {
                            "id": "3001",
                            "label": "仓库地址"
                        },
                        {
                            "id": "7",
                            "label": "库位列表"
                        },
                        {
                            "id": "215",
                            "label": "库位库存"
                        }
                    ]
                },
                {
                    "id": "225",
                    "label": "海外仓管理",
                    "children": [
                        {
                            "id": "212",
                            "label": "海外仓"
                        },
                        {
                            "id": "213",
                            "label": "海外仓备货单"
                        }
                    ]
                },
                {
                    "id": "45",
                    "label": "补货",
                    "children": [
                        {
                            "id": "112",
                            "label": "采购订单"
                        },
                        {
                            "id": "109",
                            "label": "补货报表",
                            "children": [
                                {
                                    "id": "82",
                                    "label": "补货图表"
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "117",
                    "label": "FBA发货",
                    "children": [
                        {
                            "id": "83",
                            "label": "发货报表",
                            "children": [
                                {
                                    "id": "58",
                                    "label": "产品发货统计"
                                },
                                {
                                    "id": "60",
                                    "label": "承运商发货统计"
                                },
                                {
                                    "id": "103",
                                    "label": "发货订单执行明细"
                                },
                                {
                                    "id": "119",
                                    "label": "头程费用分摊"
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "50",
                    "label": "操作",
                    "children": [
                        {
                            "id": "76",
                            "label": "调库单"
                        },
                        {
                            "id": "73",
                            "label": "代料单"
                        },
                        {
                            "id": "80",
                            "label": "出库单"
                        },
                        {
                            "id": "91",
                            "label": "入库单"
                        },
                        {
                            "id": "46",
                            "label": "库存盘点"
                        }
                    ]
                },
                {
                    "id": "3003",
                    "label": "报表",
                    "children": [
                        {
                            "id": "53",
                            "label": "库存报表"
                        },
                        {
                            "id": "120",
                            "label": "库存详情"
                        },
                        {
                            "id": "107",
                            "label": "每日库存"
                        },
                        {
                            "id": "96",
                            "label": "出入库明细"
                        },
                        {
                            "id": "33",
                            "label": "周转报告"
                        },
                        {
                            "id": "6103",
                            "label": "本地滞销"
                        }
                    ]
                }
            ]
        },
        {
            "id": "10",
            "label": "发货",
            "children": [
                {
                    "id": "11",
                    "label": "发货处理",
                    "children": [
                        {
                            "id": "57",
                            "label": "FBA发货规划"
                        },
                        {
                            "id": "36",
                            "label": "发货单"
                        },
                        {
                            "id": "27",
                            "label": "货件处理"
                        }
                    ]
                },
                {
                    "id": "12",
                    "label": "发货基础数据",
                    "children": [
                        {
                            "id": "93",
                            "label": "物流公司"
                        },
                        {
                            "id": "141",
                            "label": "发货地址"
                        },
                        {
                            "id": "62",
                            "label": "标签打印"
                        }
                    ]
                },
                {
                    "id": "1113",
                    "label": "报表分析",
                    "children": [
                        {
                            "id": "142",
                            "label": "FBA库存"
                        },
                        {
                            "id": "23",
                            "label": "FBA每日库存"
                        },
                        {
                            "id": "4000",
                            "label": "发货统计"
                        },
                        {
                            "id": "4001",
                            "label": "费用分摊"
                        },
                        {
                            "id": "4002",
                            "label": "发货详情"
                        }
                    ]
                }
            ]
        },
        {
            "id": "61",
            "label": "广告",
            "children": [
                {
                    "id": "133",
                    "label": " 分类广告"
                },
                {
                    "id": "3",
                    "label": "广告综合管理",
                    "children": [
                        {
                            "id": "41",
                            "label": "品牌广告"
                        },
                        {
                            "id": "79",
                            "label": "广告统计"
                        },
                        {
                            "id": "38",
                            "label": "广告检索"
                        },
                        {
                            "id": "115",
                            "label": "广告报表下载"
                        }
                    ]
                }
            ]
        },
        {
            "id": "84",
            "label": "财务",
            "children": [
                {
                    "id": "4",
                    "label": "AMZ财务",
                    "children": [
                        {
                            "id": "401",
                            "label": "店铺利润"
                        },
                        {
                            "id": "20",
                            "label": "利润报表"
                        },
                        {
                            "id": "402",
                            "label": "月度利润"
                        },
                        {
                            "id": "403",
                            "label": "账期SKU"
                        },
                        {
                            "id": "64",
                            "label": "订单销售"
                        },
                        {
                            "id": "116",
                            "label": "业绩报告"
                        },
                        {
                            "id": "14",
                            "label": "回款记录"
                        },
                        {
                            "id": "971",
                            "label": "赔偿费用"
                        },
                        {
                            "id": "972",
                            "label": "FBA仓储费"
                        }
                    ]
                },
                {
                    "id": "211",
                    "label": "财务单据"
                },
                {
                    "id": "216",
                    "label": "财务其他",
                    "children": [
                        {
                            "id": "217",
                            "label": "其它费用"
                        },
                        {
                            "id": "218",
                            "label": "批次成本"
                        },
                        {
                            "id": "88",
                            "label": "库存货值"
                        },
                        {
                            "id": "28",
                            "label": "采购记账"
                        }
                    ]
                },
                {
                    "id": "214",
                    "label": "财务配置",
                    "children": [
                        {
                            "id": "219",
                            "label": "汇率管理"
                        }
                    ]
                }
            ]
        },
        {
            "id": "13",
            "label": "客服",
            "children": [
                {
                    "id": "123",
                    "label": "订单处理",
                    "children": [
                        {
                            "id": "124",
                            "label": "VAT发票"
                        }
                    ]
                },
                {
                    "id": "132",
                    "label": "评论",
                    "children": [
                        {
                            "id": "75",
                            "label": "差评管理"
                        },
                        {
                            "id": "42",
                            "label": "评论跟踪"
                        }
                    ]
                }
            ]
        },
        {
            "id": "87",
            "label": "设置",
            "children": [
                {
                    "id": "5",
                    "label": "授权管理",
                    "children": [
                        {
                            "id": "26",
                            "label": "1688绑定"
                        },
                        {
                            "id": "100",
                            "label": "店铺管理"
                        },
                        {
                            "id": "122",
                            "label": "账号管理"
                        },
                        {
                            "id": "317",
                            "label": "角色权限"
                        },
                        {
                            "id": "108",
                            "label": "我的应用",
                            "children": [
                                {
                                    "id": "56",
                                    "label": "订单地址下载"
                                }
                            ]
                        },
                        {
                            "id": "6101",
                            "label": "部门管理"
                        }
                    ]
                }
            ]
        },
        {
            "id": "1010",
            "label": "系统",
            "children": [
                {
                    "id": "22",
                    "label": "系统管理",
                    "children": [
                        {
                            "id": "72",
                            "label": "管理菜单"
                        },
                        {
                            "id": "2201",
                            "label": "数据字典"
                        },
                        {
                            "id": "51",
                            "label": "文档API"
                        },
                        {
                            "id": "99",
                            "label": "FBA费设置"
                        },
                        {
                            "id": "113",
                            "label": "权限管理"
                        },
                        {
                            "id": "90",
                            "label": "系统任务"
                        },
                        {
                            "id": "104",
                            "label": "公告发布"
                        },
                        {
                            "id": "105",
                            "label": "帮助页面"
                        },
                        {
                            "id": "54",
                            "label": "邮件模板"
                        },
                        {
                            "id": "86",
                            "label": "客户管理"
                        },
                        {
                            "id": "40",
                            "label": "套餐管理"
                        },
                        {
                            "id": "118",
                            "label": "Store管理"
                        }
                    ]
                }
            ]
        }
    ]
const getTreeItemById = (id,data)=>{
    if(!isArray(data)) return {}

    for(let i=0;i<data.length;i++){
        let item = data[i];
        console.log("item.id",item.id,"id",id)
        if(item.id==id){
            return item;
        }else{
            if(item.children) return getTreeItemById(id,item.children)
        }
    }
}
console.log("data",data1)
console.log("result",getTreeItemById("66600",data1))

</script>

<template>
    <div>

        hello
    </div>
</template>
