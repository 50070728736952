import {defineStore} from 'pinia'
import {reactive, computed, watch, toRefs} from 'vue'

export const usePermission = defineStore('usePermission', () => {
	const state = reactive({
		permission: new Set() //权限
	})  // 定义数据

	const set = async (rules) => {
		state.permission = new Set(rules);
	}

	return {...toRefs(state), set}
})
