<template>
	<div class="header-right-content">


		<div class="message">
			<remind theme="outline" size="16"/>
		</div>
		<div class="phone">
			<headset-one theme="outline" size="16"/>
		</div>
		<div class="message pointer" @click="changeTheme" v-if="lightSkin">
			<brightness theme="outline" size="18"/>
		</div>
		<!--        <div class="message pointer" v-else @click="changeTheme">-->
		<!--            <moon theme="outline" size="16"/>-->
		<!--        </div>-->
		<div class="avatar">
			<el-menu
				:default-active="'1'"
				class="el-menu-avatar"
				mode="horizontal"
				:collapse-transition="false"
				:ellipsis="false"
			>
				<el-sub-menu popper-class="el-menu-popper-avatar" index="1">
					<template #title>
					   <span class="el-dropdown-link">
					   	<el-avatar v-if="userinfo.avatar" :src="userinfo.avatar"></el-avatar>
					   	<el-avatar v-else>
					   		<div style="padding-top:1px;color:var(--el-color-primary);font-size:10px;">{{ userinfo.avatarName }}</div>
						</el-avatar>
					   	<span class="user-name">{{ userinfo.name }}</span>
					   </span>
					</template>
					<el-menu-item index="1-1" disabled class="company"> {{ userinfo.company }}</el-menu-item>
					<el-menu-item index="1-2" @click="goTocenter"> 个人中心</el-menu-item>
					<el-sub-menu index="1-3">
						<template #title>切换账号</template>
						<el-menu-item index="1-3-1" @click="loginMyUserName(item)" :class="item.isactive? 'active':''"
									  v-for="(item,index) in bindlist" :key="index">{{ item.account }}
							<el-icon v-if="item.isactive" style="padding-left: 10px;">
								<Check/>
							</el-icon>
						</el-menu-item>
						<el-menu-item index="1-3-1000" @click="bindVisible=true">
							<el-icon>
								<people-plus size="14"/>
							</el-icon>
							添加账号
						</el-menu-item>
					</el-sub-menu>
					<el-menu-item index="1-5" @click="clearCath">清缓存</el-menu-item>
<!--					<el-menu-item index="1-6" v-if="isSSO()" @click="backOldsys">返回旧系统</el-menu-item>-->
					<el-menu-item index="1-4" @click="logout">退出</el-menu-item>
				</el-sub-menu>
			</el-menu>

		</div>
	</div>
	<!-- 绑定账号 -->
	<el-dialog v-model="bindVisible" title="绑定其它账号" :destroy-on-close='true' width="560px">
		<el-form :inline="true" :model="bindForm" class="form-width-fill" label-width="auto">
			<el-form-item label="其它账号">
				<el-input v-model="bindForm.account" placeholder="请输入要绑定的账号"></el-input>
			</el-form-item>
			<el-form-item label="账号密码">
				<el-input v-model="bindForm.password" type="password" show-password placeholder="请输入密码"></el-input>
			</el-form-item>
		</el-form>
		<template #footer>
	 		<span class="dialog-footer">
	 			<el-button @click="bindVisible = false">取消</el-button>
	 			<el-button type="primary" @click="submitBind">确认</el-button>
	 		</span>
		</template>
	</el-dialog>
</template>
<script>
import request from "@/utils/request";
import axios from 'axios'
import {
	Remind,
	HeadsetOne,
	Brightness,
	PeoplePlus,
	Moon,
} from '@icon-park/vue-next';
import {Check} from '@element-plus/icons-vue';
import {mapState} from 'pinia'
import userApi from '@/api/sys/admin/userApi';
import {useUserinfo} from '@/store/userinfo'
import {useRouterInfo} from '@/store/routerInfo'
/* 清除缓存 */
import {
	menuApi
} from '@/api/sys/admin/menuApi';
import {toggleDark} from "@/components/composables";
import {
	ElMessage
} from 'element-plus';

export default {
	name: "HeaderAvatar",
	components: {
		Remind,
		HeadsetOne,
		Brightness,
		Moon,
		PeoplePlus,
		Check,
	},
	emits: ['changeSKin'],
	data() {
		return {
			bindVisible: false,
			lightSkin: false,
			bindlist: [],
			bindForm: {},
			userinfos:[]
		}
	},
	computed: {
		...mapState(useUserinfo, {userId: "id"})
	},
	created() {
		// this.getData();
		this.loadBindList();
	},
	setup(){
		const userinfo = useUserinfo();

		return {userinfo};
	},
	methods: {
		loginMyUserName(row){
			// 判断：当前切换的是否是主账号，是就直接更新信息跳转
			let myUserInfo=JSON.parse(localStorage.getItem('myUserInfo'))
			if(row.account==myUserInfo.account){
				localStorage.setItem("jsessionid", myUserInfo.session);
				localStorage.setItem("logintype", "api");
				window.location = "/home";
				return
			}
			// 不是主账号：判断是否本地有缓存信息，没有就从新请求
			this.userinfos=localStorage.getItem('userInfos')?JSON.parse(localStorage.getItem('userInfos')):[]
			let num=1
			if(this.userinfos){
				this.userinfos.forEach(item=>{
					if(item.account==row.account){
						num=2
						localStorage.setItem("jsessionid", item.session);
						localStorage.setItem("logintype", "api");
						window.location = "/home";
					}
				})
				if(num==1){
					this.logins(row,myUserInfo)
				}
			}else{
				this.logins(row,myUserInfo)
			}
			
		},
		logins(row,myUserInfo){
			let form={
				userId:row.userid
			}
			axios.get(`/admin/api/v1/users/changeUser?userId=${row.userid}`,{
				jsessionid:myUserInfo.session
			}).then(res => {
				if (res.data.data) {
					this.$message({
						type: "success",
						message: "登录成功!"
					});
					if(this.userinfos){
						this.userinfos.push(res.data.data)
					}else{
						this.userinfos=[res.data.data]
					}
					localStorage.setItem('userInfos',JSON.stringify(this.userinfos))
					localStorage.setItem("jsessionid", res.data.data.session);
					localStorage.setItem("logintype", "api");
					window.location = "/home";
				}
			}).finally((res) => {
			});
			// request.get("/admin/api/v1/users/changeUser", {params:form},{headers:{jsessionid:myUserInfo.session}}).then(res => {
			// 	if (res.data) {
			// 		this.$message({
			// 			type: "success",
			// 			message: "登录成功!"
			// 		});
			// 		if(this.userinfos){
			// 			this.userinfos.push(res.data)
			// 		}else{
			// 			this.userinfos=[res.data]
			// 		}
			// 		localStorage.setItem('userInfos',JSON.stringify(this.userinfos))
			// 		localStorage.setItem("jsessionid", res.data.session);
			// 		localStorage.setItem("logintype", "api");
			// 		window.location = "/home";
			// 	}
			// }).finally((res) => {
			// });
		},
		changeTheme() {
			this.lightSkin = !this.lightSkin
			toggleDark();
		},
		isSSO() {
			return localStorage.getItem("logintype") == "sso";
		},
		backOldsys() {
			window.location = 'https://erp.xddo.cn/page.do'
		},
		clearCath() {
			menuApi.cleanUserCache()
		},
		goTocenter() {
			this.$router.push({
				path: "/usercenter",
				query: {
					title: "个人中心",
					path: "/usercenter",
				}
			})
		},
		submitBind() {
			let self = this;
			var data = {};
			data.openid = "";
			data.password = self.bindForm.password;
			data.account = self.bindForm.account;
			data.appType = "website";
			userApi.openidbind(data).then((res) => {
				self.$message({
					type: "success",
					message: "操作成功!"
				});
				self.bindVisible = false;
				self.loadBindList();
			});
		},
		// 切换公司账号
		changeAccount(item) {
			let self = this;
			var data = {};
			userApi.getbindId({account: item.account}).then((res) => {
				data.openid = res.data;
				data.account = item.account;
				data.appType = "website";
				userApi.changeLoginWechatApp(data).then((res) => {
					var query = this.$router.currentRoute.value.query;
					if (query["title"] && query["path"] && Object.keys(query).length == 2) {
						window.location.reload();
					} else {
						window.location = "/home";
					}
				});
			});


		},
		// 退出登录
		logout() {
			let self = this;
			const routerInfo = useRouterInfo();
			routerInfo.hasload=false //退出登录时，允许下次登录从新加载菜单请求
			var logintype = localStorage.getItem("logintype");
			let jsessionid = localStorage.getItem("jsessionid");
			request.get("/api/auth/apilogout").then(res => {
				if ("api" == logintype) {
					if (res.data == "success") {
						self.$message({
							type: "success",
							message: "登出成功!"
						});
						localStorage.removeItem("jsessionid");//唯一ID
						localStorage.removeItem("logintype");
						self.$router.push({path: "/login"})//跳转登录页
						routerInfo.router=[] //退出登录时清空菜单，下次登录从新加载
						localStorage.removeItem('userInfos')
						localStorage.removeItem('myUserInfo')
					} else {
						self.$message({
							type: "error",
							message: "登出失败!"
						});
					}
				} else {
					localStorage.removeItem("jsessionid");
					localStorage.removeItem("logintype");
					let authserver = localStorage.getItem("authserver");
					location = authserver + "/logout";
				}
			});

		},
		// 初始化账号信息
		loadBindList() {
			let self = this;
			let myUserInfo=JSON.parse(localStorage.getItem('myUserInfo'))
			let dqToken=localStorage.getItem('jsessionid')
			axios.get('/admin/api/v1/users/findbindlist',{
				headers:{
					Jsessionid:myUserInfo.session
				}
			}).then((res) => {
				var list = [{
					account:myUserInfo.account,
					userid:myUserInfo.id,
					isactive:dqToken==myUserInfo.session?true:false,
					companyName:myUserInfo.companyName
				}];
				if (res.data.data) {
					res.data.data.forEach(item => {
						var data = {userid: item.id, isactive: false, companyName: item.companyName};
						data.account = item.account;
						// 绑定账号列表：id一致的显示勾选icon
						if (this.userId == item.id) {
							data.isactive = true;
						}
						// if (data.companyName) {
						// 	data.companyName = data.companyName
						// }
						list.push(data);
					});
				}
				self.bindlist = list;
			});
		}
	},
}
</script>
<style>
.phone,
.message {
	line-height: 34px;
}

.phone,
.message .i-icon {
	display: block;
	padding: 0px 8px;
}

.phone > .i-icon > svg,
.message > .i-icon > svg {
	vertical-align: middle;
}

.avatar {
	line-height: 34px;
	display: flex;
	align-items: center;
}

.header-right-content {
	display: flex;
	align-items: center;
}

.header-right-content .el-avatar {
	width: 24px;
	height: 24px;
}

.user-name {
	font-size: 13px;
	margin-left: 8px;
}

.el-avatar {
	position: relative;
	background-color: #e8e8e8;
}

.dark .el-avatar {
	position: relative;
	background-color: #EBEDF0;
}

.el-menu-avatar .el-sub-menu__icon-arrow {
	display: none;
}

.el-menu-avatar {
	border-bottom: none !important;

}

.el-menu-avatar .el-sub-menu__title {
	line-height: 0px !important;
	padding-right: 0px !important;
	border-bottom: 0 !important;
	padding-left: 10px;
}

.el-menu-avatar .el-sub-menu.is-active .el-sub-menu__title {
	border-bottom: none !important;
	padding-right: 0px !important;
}

</style>
<style scoped>
.company {
	border-bottom: 1px solid #e5e5e5 !important;
	margin-bottom: 5px !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;

	opacity: .66 !important;
}
</style>
