<template>
	<div id="menubar" class="app-asideMenu" :class="theme">

		<el-scrollbar height="calc(100vh)">
			<div class="aside-flex" style="height:calc(100vh)">
				<div>
					<div class="logo pointer" @click="goHome">
						<el-image
							:src="require('@/assets/image/common/color-logo.png')"
						></el-image>
					</div>
					<el-menu
						:default-active="activeIndex + ''"
						class="el-menu-vertical"
						:default-openeds="[1]">
						<!--主菜单-->
						<!--						@mouseenter="changeMenu(item.id,index)"-->
						<el-menu-item
							@mouseleave="laveMenu"

							@click="changeDrawer(item, index)"
							v-for="(item,index) in menuData"
							:key="item.id" :index='index+""'>

							<icon-park
								v-if="theme=='themeLight'"
								:type="item.iconName"
								theme="outline"
								:size="18"
								:strokeWidth="3"/>
							<icon-park
								v-else
								:type="item.iconName"
								theme="outline"
								:size="18"
								:strokeWidth="4"/>
							<span class="aside-menu-name">{{ item.name }}</span>
						</el-menu-item>

					</el-menu>
				</div>
				<div>
					<HeaderPlatform/>
				</div>
			</div>
		</el-scrollbar>
		
		<!--弹出层!-->
		<el-drawer v-model="drawer" custom-class="submenu-wrap" size="" direction="ltr" :title="menuData[activeIndex] && menuData[activeIndex].name" :with-header="false"
				   :destroy-on-close='true' :show-close="false">

			<el-scrollbar height="calc(100vh - 80px)">

				<!--				<div v-show='submenu.isShow' v-for="submenu in submenus.list" :key="submenu.id">-->


				<div v-if="menuData[activeIndex] && menuData[activeIndex]['children']">
					<div class="item" v-for="subgroup in menuData[activeIndex]['children']" :key="subgroup.id"
						 style="margin-bottom:20px;">

						<span class="font-extraSmall">{{ subgroup.name }}</span>
						<div v-for='sub in subgroup.children' :key='sub.id'>
							<a class="text submenu-item" v-if="((sub.isout))" :href="sub.path" target="_blank">
								{{ sub.name }}
							</a>
							<router-link v-if="(!(sub.isout))" class="text submenu-item" active-class="active" :to="{
                          path:sub.path,
                          query:{
                              title:sub.name,
                              path:sub.path,
                          }
                      }">{{ sub.name }}
							</router-link>
						</div>
					</div>
				</div>
			</el-scrollbar>

			<template #footer="">
				<div class="submenu-footer">
					Copyright © 星点点网络
				</div>
			</template>


		</el-drawer>
	</div>
</template>

<script>
import {IconPark} from '@icon-park/vue-next/es/all';
import {
	Commodity,
	SalesReport,
	ShoppingBag,
	Home,
	Ship,
	Ad,
	Finance,
	HeadsetOne,
	Config,
	Workbench,
	Calculator,
	Cruise,
	HomeTwo
} from '@icon-park/vue-next';
import {defineComponent, ref, reactive, watch, onMounted} from 'vue'
import {useRoute} from 'vue-router'
import {menuApi} from '@/api/sys/admin/menuApi';
import HeaderPlatform from "../Header/HeaderPlatform";
import router from "@/router";
import {useRouterInfo} from "@/store/routerInfo";
import {storeToRefs} from 'pinia'

export default defineComponent({
	name: "AsideMenu",
	components: {
		Cruise, HeaderPlatform,
		HomeTwo,
		Commodity,
		SalesReport,
		Calculator,
		ShoppingBag,
		Home,
		Ship, Ad, Finance,
		HeadsetOne, Config, Workbench,
		IconPark, menuApi
	},
	setup() {
		let timer = ''
		let drawer = ref(false);
		let activeIndex = ref(0);
		let theme = ref("themeLight");
		const routerInfo = useRouterInfo();
		const {menuData} = storeToRefs(routerInfo);
		//监听路由
		let route = useRoute();
		watch(() => route.path, () => {
			drawer.value = false
		});

		function changeDrawer(menu, currentIndex) {
			if (menu.path) {
				router.replace({name: menu.id});
			} else {

				if (drawer.value) {
					//当前为展开状态
					if (currentIndex == activeIndex.value) {
						//点击的是同一个选项卡 - 隐藏掉
						hideSubMenu();
					} else {
						//显示指定选项卡
						showSubMenu(currentIndex);
					}
				} else {
					//当前为收缩状态
					showSubMenu(currentIndex);
				}

				//drawer.value = drawer.value ? false : true;
			}
		}

		//隐藏子菜单
		function hideSubMenu() {
			activeIndex.value = 0;
			drawer.value = false;
		}

		//显示子菜单
		function showSubMenu(currentIndex) {
			activeIndex.value = currentIndex;
			drawer.value = true;
		}

		function changeMenu(id, currentIndex) {
			timer = setTimeout(() => {
				submenus.list.forEach(submenu => {
					submenu.isShow = false
				});
				const index = submenus.list.findIndex((submenu) => submenu.id === id)
				if (index >= 0) {
					const nowstaus = submenus.list[index].isShow
					submenus.list[index].isShow = !nowstaus
				} else {
					drawer.value = false
				}
				//hover当前的激活状态
				if (drawer.value == true) {
					activeIndex.value = currentIndex;
				}
			}, 250)
		}

		//离开
		function laveMenu() {
			clearInterval(timer)
		}

		//方法
		if (localStorage.getItem("theme")) {
			theme.value = localStorage.getItem("theme");
		}

		function goHome() {
			// if (theme.value == "themeBlack") {
			//     theme.value = "themeLight";
			//     localStorage.setItem("theme", "themeLight");
			// } else {
			//     theme.value = "themeBlack";
			//     localStorage.setItem("theme", "themeBlack");
			// }
			router.push({
				'path': '/home',
				query: {
					title: "首页",
					path: "/home",
					name: 'Home',
					closable: false,
					replace: false
				},
				closable: false,
				replace: false,
				meta: {keepAlive: true}
			});
			drawer.value = false
		}


		//通过路由记住菜单激活状态
		function getMenuactive() {
			let menuIndex = 0;

			menuData.value.forEach((submenu, index) => {
				submenu.children.forEach((subgroup) => {
					subgroup.children.forEach((sub) => {
						if (sub.path == route.path) {
							menuIndex = index;
							return false;
						}
					})
				})
			})

			activeIndex.value = menuIndex;
		}

		//刷新页面记住当前菜单激活状态
		onMounted(() => {
			getMenuactive()
		})
		//返回一个对象
		return {
			getMenuactive,
			laveMenu,
			timer,
			changeMenu,
			changeDrawer,
			menuData,
			activeIndex,
			drawer,
			goHome,
			theme
		}
	},

})

</script>
<style>
.dark .el-drawer {
	background-color: #363636 !important;

}

.app-asideMenu .el-drawer {
	transition: none !important;
}

.app-asideMenu .el-overlay {
	background: rgba(0, 0, 0, 0.1);
}

.themeBlack .el-button--primary.is-plain {
	--el-button-border-color: #37475a;
	--el-button-bg-color: #1f2936;
	color: #fff;
}



</style>
<style scoped>

.meun-hide {
	display: none;
}

.themeBlack, .themeBlack #menubar, .themeBlack .el-menu-vertical {
	background: #131921 !important;
}

.themeBlack .el-menu-item:hover {
	background-color: #37475a;
}

.themeBlack .el-menu-item {
	color: #dedede !important;
	opacity: 0.8;
}

.themeBlack .el-menu-item.is-active {
	background-color: #07090c;
	color: #ff6700 !important;
}

.themeBlack .text {
	text-decoration: none;
	color: #fff;
	font-size: 14px;
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color var(--el-text-color-primary), color var(--el-text-color-primary);
	color: var(--el-text-color-primary);
	display: flex;
}

.themeBlack .text:hover {
	background-color: var(--el-bg-color);
}

.themeBlack .item .active {
	background-color: var(--el-color-primary-light-9);
	color: var(--el-color-primary);
}

/* ---------------------白色主题-------------------------------*/
.themeLight, .themeLight .el-menu {
	background: var(--el-bg-color);
}

.themeLight .el-menu-item:hover {
	background-color: var(--el-border-color-light);
}

.themeLight .el-menu-item.is-active {
	background-color: var(--el-border-color-light);
}

.el-menu-vertical {
	padding-top: 16px;
}

.themeLight .text {
	text-decoration: none;
	color: var(--el-text-color-primary);
	font-size: 14px;
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color var(--el-transition-duration), color var(--el-transition-duration);
	display: flex;
}

.themeLight .text:hover {
	background-color: var(--el-bg-color);
}

.themeLight .item .active {
	color: var(--el-color-primary);
	background-color: var(--el-color-primary-light-9);
}

/*-----------------dark主题------------------------*/
.dark .themeBlack, .dark .themeBlack #menubar, .dark .themeBlack .el-menu-vertical,
.dark .themeLight, .dark .themeLight #menubar, .dark .themeLight .el-menu-vertical {
	background: #000000 !important;
}

.dark .themeBlack .el-menu-item:hover, .dark .themeLight .el-menu-item:hover {
	background-color: #000000;
}

.dark .themeBlack .el-menu-item, .dark .themeLight .el-menu-item {
	color: #dedede !important;
	opacity: 0.8;
}

.dark .themeBlack .el-menu-item.is-active, .dark .themeLight .el-menu-item.is-active {
	background-color: #000000;
	color: #ff6700 !important;
}

.dark .themeBlack .text, .dark .themeLight .text {
	text-decoration: none;
	color: #fff;
	font-size: 14px;
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color var(--el-text-color-primary), color var(--el-text-color-primary);
	color: var(--el-text-color-primary);
	display: flex;
}

.dark .themeBlack .text:hover, .dark .themeLight .text:hover {
	background-color: var(--el-bg-color);
}

.dark .themeBlack .item .active, .dark .themeLight .item .active {
	background-color: #272727;
	color: var(--el-color-primary);
}

#menubar {
	width: 64px;
}

#menubar {
	z-index: 1999;
}

.el-menu {
	border-right: 0;
}

.el-menu-item {
	flex-direction: column;
	justify-content: center;

}

.el-menu-item .i-icon {
	margin-bottom: 2px;
}

.el-menu-item span {
	line-height: 16px;
}

.aside-menu-name {
	margin-top: 3px;
}

.el-menu-item {
	height: 64px;
}

.el-menu-item .i-icon {
	display: none;
}

.el-menu-item {
	height: 30px;
}

.el-menu-item.is-active {
	color: var(--el-menu-active-color);
	font-weight: 700;
}

@media (min-height: 300px) {
	.el-menu-item {
		height: 24px;
		font-size: 12px;
	}
}

@media (min-height: 500px) {
	.el-menu-item {
		height: 30px;
		font-size: 12px;
	}
}

@media (min-height: 600px) {
	.el-menu-item .i-icon {
		display: inherit;
	}

	.el-menu-item {
		height: 48px;
		font-size: 12px;
	}
}

@media (min-height: 700px) {
	.el-menu-item .i-icon {
		display: inherit;
	}

	.el-menu-item {
		height: 53px;
		font-size: 12px;
	}
}

@media (min-height: 800px) {
	.el-menu-item .i-icon {
		display: inherit;
	}

	.el-menu-item {
		height: 60px;
		font-size: 14px;
	}
}

@media (min-height: 900px) {
	.el-menu-item .i-icon {
		display: inherit;
	}

	.el-menu-item {
		height: 65px;
		font-size: 14px;
	}
}


/*过渡动画*/

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo .el-image {
	height: 35px;
	width: 35px;
	margin-top: 16px;
}

.aside-flex {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-right: solid 1px #e6e6e6;
}

.dark .aside-flex {
	border-right: solid 1px #3e3e3e;
}

/* 子菜单 */
:deep(.el-drawer__body){
	padding-left: 0;
	padding-right: 0;
}
:deep(.el-drawer__footer){
	font-size: 12px;
	text-align: center;
	color: var(--el-text-color-placeholder);
	padding-bottom: 10px;
}
.submenu-wrap .font-extraSmall{
	padding-left: 20px;
	display: block;
	margin-bottom: 5px;
}
.submenu-wrap .submenu-item{
	color: #32353a;
	font-size: 12px;
	padding: 10px 16px 10px 36px;
}
.submenu-wrap .submenu-item.active{
	background: #f5f7fd;
}
.submenu-wrap .submenu-item:hover{
	background: #f5f7fd;
	color: var(--el-color-primary);
}

</style>