import {defineStore} from 'pinia'
import {reactive, computed, watch, toRefs} from 'vue'
import userApi from "@/api/sys/admin/userApi";

export const useUserinfo = defineStore('useUserinfo', () => {
	const state = reactive({
		id: '',
		name: '',
		company: '',
		avatar: '',
		avatarName: '',
		userType: '',
		platformId: 0,
		user: {},
		permission: new Set() //权限
	})  // 定义数据

	const set = async (userinfo) => { // 定义方法
		state.id = userinfo.id || '';
		state.name = userinfo.name || '';
		state.company = userinfo.company || '';
		state.avatar = userinfo.image || '';
		state.avatarName = userinfo.name ? userinfo.name.substring(0, 1) : '无';
		state.userType = userinfo.userType || '';
		state.platformId = userinfo.platformId?userinfo.platformId:1;
		state.user = userinfo.user || {};
	}

	const setRule = async (rules) => {

	}

	const load = async (callback) => {
		userApi.getInfo().then(response => {
			if (response && response.data) {
				set(response.data)
			}

			callback && callback();
		})
	}

	return {...toRefs(state), set, load, setRule}
})
