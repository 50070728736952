<template>
	<div class="block" >
		<view class="platform-image-wrap" size="large" @click="drawer = true" circle type="primary" plain>
<!--			<el-icon style="vertical-align: middle;font-size:18px;">-->
<!--				<laptop-computer/>-->
<!--			</el-icon>-->
			<el-image v-if="getPlatformImage" class="platform-image" :src="getPlatformImage"></el-image>
		</view>

		<!-- 弹出层 -->
		<el-drawer v-model="drawer" custom-class="platform-wrap" title="平台选择" direction="ttb" :append-to-body='false' size="">
			<el-row class="sys-content" :gutter="16" v-loading="loading">
				<el-col :lg="3" :md="4" v-for="p in plant" :key="p.plantid">
					<div class="platform" :class="{actived: p.plantid == getPlatformId}" @click="changePlatform(p.plantid)">
						<div class="addAuthorization" v-show='p.imgsty!==""'>
							<el-icon style="font-size:20px">
								<plus/>
							</el-icon>
							&nbsp;<span> 添加授权</span>
						</div>

						<el-image :class='p.imgsty' :src="p.url"></el-image>
					</div>
				</el-col>
			</el-row>
		</el-drawer>
	</div>
</template>

<script>
import {h, resolveComponent, ref, computed} from 'vue'
import {ElDivider} from 'element-plus'
import {Plus} from '@element-plus/icons-vue';
import {LaptopComputer} from '@icon-park/vue-next';
import {findItemByField} from '@/utils/index';
import {useUserinfo} from "@/store/userinfo";
import {useRouterInfo} from "@/store/routerInfo";

import {
	updateUserPlatform
} from '@/api/sys/admin/user';

export default {
	name: "HeaderPlatform",
	components: {
		LaptopComputer, Plus
	},
	setup() {
		//数据
		let plant = [
			// {plantid: 1, url: require('@/assets/image/plantlogo/unauthorized-amz.png'), imgsty: ''},
			{plantid: 1, url: require('@/assets/image/plantlogo/unauthorized-lazada.png'), imgsty: ''},//lazada
			{plantid: 2, url: require('@/assets/image/plantlogo/unauthorized-spee.png'), imgsty: ''},//shopee
			{plantid: 3, url: require('@/assets/image/plantlogo/tiktok.png'), imgsty: ''},//tiktok
			// {plantid: 4, url: require('@/assets/image/plantlogo/unauthorized-smt.png'), imgsty: ''},
			// {plantid: 5, url: require('@/assets/image/plantlogo/unauthorized-ebay.png'), imgsty: ''},

			// {plantid: 'amazon', url: require('@/assets/image/plantlogo/unauthorized-jd.png'), imgsty: ''},
			//
			// {plantid: 'amazon', url: require('@/assets/image/plantlogo/unauthorized-wish.png'), imgsty: 'img-gray'},
			// {plantid: 'amazon', url: require('@/assets/image/plantlogo/unauthorized-smt.png'), imgsty: ''},
			//
			// {plantid: 'amazon', url: require('@/assets/image/plantlogo/unauthorized-cd.png'), imgsty: 'img-gray'},
			// {plantid: 'amazon', url: require('@/assets/image/plantlogo/unauthorized-dh.png'), imgsty: 'img-gray'},
			// {plantid: 'amazon', url: require('@/assets/image/plantlogo/unauthorized-walmart.png'), imgsty: 'img-gray'},
			// {plantid: 'amazon', url: require('@/assets/image/plantlogo/unauthorized-kilimall.png'), imgsty: 'img-gray'},
			// {plantid: 'amazon', url: require('@/assets/image/plantlogo/unauthorized-mgt.png'), imgsty: 'img-gray'},
			// {plantid: 'amazon', url: require('@/assets/image/plantlogo/unauthorized-woc.png'), imgsty: 'img-gray'},


		];
		let loading = ref(false);
		const userinfo = useUserinfo();
		const routerInfo = useRouterInfo();

		//切换平台
		function changePlatform(plantid){
			if(this.loading)return;
			this.loading = true;

			updateUserPlatform(plantid).then(data => {

				//window.location.reload();
				userinfo.load();
				routerInfo.load();

			}).finally(() => {
				this.loading = false;
			});
		}

		const getPlatformImage = computed(() => {
			let item = findItemByField(userinfo.platformId, plant, "plantid");

			return item == null ? '' : item.url;
		});
		
		const getPlatformId = computed(() => {
			return userinfo.platformId;
		});


		return {
			size: 0,
			plant,
			loading,
			spacer: h(ElDivider, {direction: 'vertical'}),
			drawer: ref(false),
			changePlatform,
			getPlatformImage,
			getPlatformId
		}
	},
}
</script>
<style>
.addAuthorization {
	position: absolute;
	opacity: 0;
	color: var(--el-color-primary);
	display: flex;
}

.platform:hover .addAuthorization {
	opacity: 1;
	transition: opacity 0.3s
}

.platform:hover .img-gray {
	opacity: 0;
	transition: opacity 0.3s
}

.platform {
	height: 72px;
	align-items: center;
	display: flex !important;
	justify-content: center;
	border: 1px solid var(--el-border-color-base);
	cursor: pointer;
	border-radius: 2px;
	transition: all .2s ease-in-out;
}

.platform .img-gray {
	opacity: 0.5;
}

.platform:hover, .platform.actived {
	border-color: var(--el-color-primary-light-2);
}

.block .el-overlay {
	top: 34px !important;
	z-index: 2000 !important;
}

.block .el-overlay .el-drawer.ttb {
	border-top: 1px solid #eee
}

@media screen and (max-width: 1440px) {
	.platform {
		height: 64px;
	}
}

.sys-content .is-guttered {
	padding-bottom: 16px;
}

.block {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
}

.ttb .el-drawer__header {
	margin-bottom: 0px;
}

.platform-image-wrap{
	border: 0;
	padding: 0 !important;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background: white;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0;
}
.platform-image-wrap:hover {
	background: #ffffffa8 !important;
}

.platform-wrap .el-drawer__body{
	padding-left: var(--el-drawer-padding-primary) !important;
	padding-right: var(--el-drawer-padding-primary) !important;
	box-sizing: border-box;
}

</style>