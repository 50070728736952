<template>
	<div class="login-bg">
		<div class="screen-height">

			<div class="screen-logo">
				<el-image :src="require('@/assets/image/common/logo-white.png')"></el-image>
			</div>

			<div class="login-content">
				<div class="left-content">
					<div class="content-mark">
						<p style="font-weight: bold; font-size: 35px;">我们用<br>大眼睛看世界</p>
						<p style="font-size: 14px; margin-top: 15px; line-height: 1.8;">
							有两种类型的人会告诉你，在这个世界上你不能<br>有所作为：那些害怕尝试的人和那些害怕你会成功的人。
						</p>
					</div>

					<div class="content-video">
						<div class="video-btn">
							<div class="video-btn-shape"></div>
						</div>

						<div class="video-mark">
							<p style="font-size: 14px;">观看视频</p>
							<p style="font-size: 16px; margin-top: 5px">是什么让我们与众不同？</p>
						</div>
					</div>
				</div>

				<el-card class="login-box">

					<h4 class="login-title">登录</h4>
					<h5 class="login-mark">欢迎登录星点ERP</h5>

					<el-form class="m-t-24" ref="form" :rules="rules" :model="form" size="normal">
						<el-form-item prop="account">
							<el-input :prefix-icon="UserIcon" v-model="form.account" size="large" placeholder="请输入手机号或邮箱"
								clearable>
								<template #prefix>
									<el-icon class="font-medium el-input__icon">
										<UserIcon />
									</el-icon>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input :prefix-icon="Lock" v-model="form.password" size="large" show-password
								placeholder="请输入密码">
								<template #prefix>
									<el-icon class="font-medium el-input__icon">
										<lock />
									</el-icon>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<div class="flex-center-between" style="flex:1">
								<el-checkbox class="text-gray" v-model="checked2" label="记住账号" />
								<el-button type="info" link @click="goResetPassword">忘记密码</el-button>
							</div>
						</el-form-item>
						<el-form-item style="text-align: center;">
							<el-button :loading="loading" style="width: 100%;" type="primary" size="large"
								@click="userLoginSub">登录
							</el-button>
						</el-form-item>
					</el-form>

					<div class="quick-login-btns">
						<div class="quick-login-btn bg-phone">
							<Iphone theme="outline" size="16" />
						</div>
						<div class="quick-login-btn bg-wechat">
							<Wechat theme="outline" size="16" />
						</div>
						<div class="quick-login-btn bg-qq">
							<TencentQq theme="outline" size="16" />
						</div>
					</div>

					<div class="font-extraSmall text-center flex-center ">
						<span>没有账号吗&nbsp;</span>
						<el-link @click="userRegister" type="primary" :underline="false" class="font-12">
							注册新账号
						</el-link>
					</div>
				</el-card>
			</div>
		</div>
	</div>
</template>

<script>
import { Lock, User as UserIcon } from '@element-plus/icons-vue';
import request from "@/utils/request";
import {
	Iphone, Wechat, TencentQq
} from '@icon-park/vue-next';
import { ElMessage } from "element-plus";
import {useRouterInfo} from '@/store/routerInfo'

export default {
	name: "Login",
	components: {
		Iphone, Wechat, TencentQq,
		UserIcon, Lock
	},
	data() {
		return {
			form: {},
			rules: {
				account: [{ message: '请输入账号', required: true, trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
			},
			loading: false
		}
	},
	methods: {
		// 跳转注册页
		userRegister() {
			this.$router.push("/register");
		},
		// 验证登录表单是否填写
		userLoginSub() {
			this.$refs.form.validate((valid, fields) => {
				if (valid) {
					this.userLogin();
				}
			})
		},
		// 登录
		userLogin() {
			if (this.loading) return;
			this.loading = true;
			request.post("/api/auth/login", this.form).then(res => {
				if (res.data) {
					this.$message({
						type: "success",
						message: "登录成功!"
					});
					let path = sessionStorage.getItem("old_url_before_login");
					localStorage.setItem("jsessionid", res.data.session);
					localStorage.setItem("logintype", "api");
					localStorage.setItem('userInfos',[])
					localStorage.setItem('myUserInfo',JSON.stringify(res.data))
					if (path) {
						if (path.indexOf("auth/getJSession") > 0 || path.indexOf("ssologin") > 0 || path == "/" || path == "/login" || path.startsWith("/login")) {
							this.$router.push("/home");
						} else {
							this.$router.push(path);
						}
					} else {
						this.$router.push("/home");
					}
				} else {
					if (res.msg) {
						this.$message({
							type: "error",
							message: "登录失败!" + res.msg
						});
					} else {
						this.$message({
							type: "error",
							message: "登录失败!"
						});
					}

				}
			}).finally((res) => {
				this.loading = false;
			});
		},
		goResetPassword() {
			this.$router.push("/resetPassword");
		}
	},
	mounted() {
		const routerInfo = useRouterInfo();
		routerInfo.hasload=false //退出登录时，允许下次登录从新加载菜单请求
		// 有token直接进入页面
		// let jsessionid = localStorage.getItem("jsessionid");
		// if (jsessionid) {
		// 	this.$router.push("/home");
		// }
	}
}
</script>

<style scoped>
.login-bg {
	background: linear-gradient(to right bottom, #9879de, #2cdacb);
}

.screen-height {
	height: calc(100vh);
}

.screen-logo {
	width: 200px;
	position: absolute;
	left: 40px;
	top: 30px;
}

.login-content {
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	width: 70%;
	left: 15%;
	transform: translateY(-50%);
}

.login-box {
	width: 420px;
	padding: 20px;
	border: none;
	background-color: rgba(255, 255, 255, 0.8);
}

.login-box .login-title {
	color: #160f53;
	font-size: 20px;
}

.login-box .login-mark {
	color: #160f53;
	font-size: 14px;
	margin-top: 5px;
}

.font-12 {
	font-size: 12px;
}

.left-content {
	width: 36%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.content-mark {
	color: white;
}

.content-video {
	color: white;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.content-video .video-btn {
	background: linear-gradient(to right bottom, #1de1d0 50%, #4ae7d9 70%, #77ece2 100%);
	display: flex;
	width: 100px;
	height: 60px;
	border-radius: 3px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
}

.content-video .video-btn:hover {
	opacity: 0.8;
}

.video-btn .video-btn-shape {
	border-top: 8px solid transparent;
	border-left: 12px solid white;
	border-bottom: 8px solid transparent;
	margin-left: 6px;
}

.content-video .video-mark {
	margin-left: 20px;
}

.m-t-24 {
	margin-top: 24px;
}

.quick-login-btns {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.quick-login-btns .quick-login-btn:hover {
	opacity: 0.8;
}

.quick-login-btns .quick-login-btn {
	margin: 0 10px;
	cursor: pointer;
	display: flex;
	width: 30px;
	height: 30px;
	justify-content: center;
	align-items: center;
	line-height: 1;
	overflow: hidden;
	border-radius: 100%;
	cursor: pointer;
	color: white;
}

.m-t-64 {
	margin-top: 64px;
	justify-content: center;
}

.font-extraSmall {
	margin-top: 24px;
	justify-content: center;
}

.text-gray {
	color: var(--el-color-info)
}</style>