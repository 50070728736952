import {defineStore} from 'pinia'
import {reactive, computed, watch, toRefs} from 'vue'
import {listRoutes} from "@/api/sys/admin/menu";
import {usePermission} from "@/store/permission";
import router from "@/router";
import {sortByKey} from "@/utils";

export const useRouterInfo = defineStore('useRouterInfo', () => {
	const state = reactive({
		router: [],
		hasload:false
	})  // 定义数据
	const permission = usePermission();

	const set = async (data) => { // 定义方法
		state.router = data || [];
	}

	const load = async (func) => {
		await listRoutes().then(result => {

			if (result == undefined || result.code == 'A0231' || result.code == 'A0200' || result.code == 'S0003' || result.code == 'S0002' || result.code == 'S0001') {
				localStorage.removeItem("jsessionid");

				if (!sessionStorage.getItem("old_url_before_login")) {
					sessionStorage.setItem("old_url_before_login", window.location.pathname + window.location.search);
				}

				router.push("/login");
			} else {
				set(result.data)
				if (result.data && result.data.length > 0) {
					permission.set(result.data[0].meta.permissions);
				}

				func && func(result.data);
			}
		}).catch(error => {
			localStorage.removeItem("jsessionid");
			sessionStorage.setItem("old_url_before_login", window.location.pathname + window.location.search);
			setTimeout(function () {
				window.location = window.location.origin + "/login";
			}, 1000);
		});
	}

	const menuData = computed(() => {
		return recursionData(state.router);
	})


	/**
	 * 递归映射数据
	 * @param data
	 */
	function recursionData(data){
		let result = [];

		for(let item of data){
			let children = item.children ? recursionData(item.children) : [];

			let menuitem = {
				id: item.name || '',
				name: item.meta.title || '',
				iconName: item.meta.icon || '',
				path: item.path || '',
				isout: false,
				children: children
			};

			result.push(menuitem);
		}

		return result;
	}


	return {...toRefs(state), set, load, menuData}
})
