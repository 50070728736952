<template>

  <div class="login-bg">
    <div class="screen-height">

      <div class="screen-logo">
        <el-image :src="require('@/assets/image/common/logo-white.png')"></el-image>
      </div>

      <div class="login-content">
        <div class="left-content">
          <div class="content-mark">
            <p style="font-weight: bold; font-size: 35px;">我们用<br>大眼睛看世界</p>
            <p style="font-size: 14px; margin-top: 15px; line-height: 1.8;">有两种类型的人会告诉你，在这个世界上你不能<br>有所作为：那些害怕尝试的人和那些害怕你会成功的人。
            </p>
          </div>

          <div class="content-video">
            <div class="video-btn">
              <div class="video-btn-shape"></div>
            </div>

            <div class="video-mark">
              <p style="font-size: 14px;">观看视频</p>
              <p style="font-size: 16px; margin-top: 5px">是什么让我们与众不同？</p>
            </div>
          </div>
        </div>

        <el-card class="login-box">

          <h4 class="login-title">注册</h4>
          <h5 class="login-mark">欢迎注册星点ERP</h5>

          <el-form ref="myform" :model="form" :rules="rules" size="normal" class="m-t-24">
            <el-form-item label="手机号">
              <el-input   v-model="form.account"  size="large"   placeholder="请输入手机" clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="验证码">
              <div  style="flex:1" class="flex-center-between">
                <el-input style="margin-right:8px" v-model="form.code"   size="large" placeholder="请输入验证码" :readonly='readonly' @focus='handlerIptClick'></el-input>
                <el-button v-if="waitSecond>0" size="large" disabled type="primary" loading plain>需要等待<span>（{{waitSecond}}s)</span> </el-button>
                <el-button v-else type="primary" size="large" @click.stop="sendSMSCode" plain>获取手机验证码 </el-button>
              </div>
            </el-form-item >
            <el-form-item label="密　码">
              <el-input   v-model="form.password"  size="large"  show-password   placeholder="请输入密码" :readonly='readonly' @focus='handlerIptClick'>
              </el-input>
            </el-form-item >
            <el-form-item label="公司名">
              <el-input   v-model="form.company"  size="large"  placeholder="请输入公司名称" clearable>
              </el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button :loading="loading" style="width: 100%;" type="primary" size="large" @click="userRegister">注册</el-button>
            </el-form-item>
          </el-form>

          <div class="font-extraSmall text-center flex-center ">
            <span>已有帐号&nbsp;</span>
            <el-link type="primary" @click="userLogin"  :underline="false"  class="font-12">
              去登陆
            </el-link>
          </div>
        </el-card>
      </div>
    </div>
  </div>

</template>

<script setup>
import {ref,reactive,toRefs} from"vue"
import {Lock, User} from '@element-plus/icons-vue';
import {useRouter } from 'vue-router';
import {ElForm,ElMessage} from "element-plus";
import userApi from "@/api/sys/admin/userApi.js"
import {Iphone, TencentQq, Wechat} from "@icon-park/vue-next";
const myform =ref(ElForm);
let router = useRouter();
const invitecode=router.currentRoute.value.query.invitecode;
const salekey=router.currentRoute.value.query.salekey;
var validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== state.form.password) {
    callback(new Error('两次输入密码不一致!'))
  } else {
    callback()
  }
}
const state=reactive({
  form:{account:'',smscode:'',password:"",repassword:""},
  waitSecond:0,
  loading: false,
  readonly:true,
  rules:{
    //account: [  {validator: checkPhone, trigger: 'blur'} ],
    oldpassword:[
      { required: true, message: '请输入密码', trigger: 'blur' },
      { pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,30}$/, message: '密码为数字，小写字母，大写字母，特殊符号 至少包含三种，长度为 8 - 30位 ' },
    ],
    password: [ { required: true, validator: validatePass, trigger: 'blur' }  ]
  }
});
const { form, loading,rules,waitSecond,readonly} = toRefs(state);

// 输入框获取焦点时解除只读状态（只读用于防止新增账号时，自动被浏览器保存的账号密码填充进去）
function handlerIptClick(){
	state.readonly = false
}
function userLogin(){
  router.push("/login");
}
function reSendSMS(){
  state.waitSecond=state.waitSecond-1;
  if(state.waitSecond<=0){
    if(interval!=null){
      window.clearInterval(interval);
      interval=null;
    }
  }
}
let interval=null;
function sendSMSCode(){
  if(state.form.account!=""){
    // 手机发验证码
    state.waitSecond=60;
    interval= window.setInterval( reSendSMS,1000)
    userApi.getSmsCodes({"mobile":state.form.account,"ftype":"register"}).then((res)=>{
      if(res.data){
        state.form.key=res.data;
      }
    }).catch(error=>{
      state.waitSecond=0;
      window.clearInterval(interval);
    });
  }else{
    ElMessage.error('手机号码不能为空');
  }
}
function userRegister(){
  if(state.loading)return;

  if(!state.form.account || state.form.account==""){
    ElMessage.error('手机不能为空');
    return;
  }
  // if(!state.form.email || state.form.email==""){
  // 	ElMessage.error('邮箱不能为空');
  // 	return;
  // }
  // if(!state.form.name || state.form.name==""){
  // 	ElMessage.error('用户名不能为空');
  // 	return;
  // }
  if(!state.form.company || state.form.company==""){
    ElMessage.error('公司名称不能为空');
    return;
  }
  if(!state.form.password || state.form.password == ""){
    ElMessage.error('密码不能为空');
    return;
  }
  if(!state.form.code){
    ElMessage.error('验证码不能为空');
    return;
  }
  state.phone=state.account;

  state.loading = true;
  userApi.register(state.form).then((res)=>{
    ElMessage.success('注册成功');
    setTimeout(function(){
      router.push({
        path:'/login',
        query:{
          title:"登录",
          path:'/login',
        }
      })
    },500);

  }).finally(() => {
    state.loading = false;
  });
}
</script>


<style scoped>
.login-bg {
  background: linear-gradient(to right bottom, #9879de, #2cdacb);
}

.screen-height {
  height: calc(100vh);
}

.screen-logo{
  width: 200px;
  position: absolute;
  left: 40px;
  top: 30px;
}

.login-content {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 70%;
  left: 15%;
  transform: translateY(-50%);
}

.login-box {
  width: 420px;
  padding: 20px;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
}
.login-box .login-title{
  color: #160f53;
  font-size: 20px;
}
.login-box .login-mark{
  color: #160f53;
  font-size: 14px;
  margin-top: 5px;
}

.font-12 {
  font-size: 12px;
}

.left-content {
  width: 36%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.content-mark {
  color: white;
}

.content-video {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content-video .video-btn {
  background: linear-gradient(to right bottom, #1de1d0 50%, #4ae7d9 70%, #77ece2 100%);
  display: flex;
  width: 100px;
  height: 60px;
  border-radius: 3px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.content-video .video-btn:hover{
  opacity: 0.8;
}

.video-btn .video-btn-shape {
  border-top: 8px solid transparent;
  border-left: 12px solid white;
  border-bottom: 8px solid transparent;
  margin-left: 6px;
}

.content-video .video-mark {
  margin-left: 20px;
}

.m-t-24 {
  margin-top: 24px;
}

.quick-login-btns{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .quick-login-btn{
    margin: 0 10px;
    cursor: pointer;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    line-height: 1;
    overflow: hidden;
    border-radius: 100%;
    cursor: pointer;
    color: white;
  }
  .quick-login-btn:hover{
    opacity: 0.8;
  }
}

.m-t-64 {
  margin-top: 64px;
  justify-content: center;
}
.font-extraSmall{
  margin-top: 24px;
  justify-content: center;
}

.text-gray {
  color: var(--el-color-info)
}
</style>